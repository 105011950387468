<template>
  <div class="wrapper">
    <div class="animated fadeIn">
      <b-row class="m-2">
        <b-col class="text-left">
          <router-link to="/"><b-icon-arrow-left /> Volver</router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-alert
            class="mx-3"
            :show="dismissCountDown"
            variant="danger"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="countDownChanged"
          >
            {{ error }}
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-row class="m-2">
            <b-col>
              <h2 class="display-3 text-left font-weight-bold">
                Proceso #{{ $route.params.process.number }},
                {{ $route.params.process.process_room.name }}
              </h2>
              <h2 class="text-left font-weight-bold">Inicio: {{ time }}</h2>
              <h2 class="text-left">
                Responsable:
                {{ $route.params.process.shift_supervisor.first_name }}
                {{ $route.params.process.shift_supervisor.last_name }} -
                {{ $route.params.process.shift_supervisor.position }}
              </h2>
            </b-col>
            <b-col>
              <p class="display-3 text-right mr-5">{{ now }}</p>
            </b-col>
          </b-row>
          <b-spinner
            v-if="loading"
            variant="primary"
            style="width: 5rem; height: 5rem;"
          ></b-spinner>
          <h1
            v-if="!loading"
            class="display-1 text-primary mb-3 font-weight-bold uppercase"
          >
            Lote en línea : {{ current_lot.number }}
          </h1>
          <b-row class="m-2">
            <b-col>
              <p class="display-4">{{ current_lot.variety }}</p>
            </b-col>
            <b-col>
              <p class="display-4">{{ current_lot.origin }}</p>
            </b-col>
          </b-row>
          <hr />
          <b-row class="mb-3">
            <b-col>
              <h1 class="display-3">Volteados : {{ totalInNet }}</h1>
            </b-col>
            <b-col>
              <h1 class="display-3">Embalados : {{ totalExportable }}</h1>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col>
              <h2 class="display-3">
                <b class="display-1">{{ totalExportableQuantity }} cajas: </b>
              </h2>
            </b-col>
          </b-row>
          <!-- <b-table striped hover :items="items"></b-table> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Pusher from "pusher-js";
import moment from "moment";
import { mapState, mapGetters } from "vuex";

let pusher = null;

moment.locale("es");
export default {
  name: "Process",
  data() {
    return {
      loading: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      error: "OCURRIO UN ERROR EN LA CARGA DE PROCESO",
      messages: [],
      time: null,
      now: null,
      counterInterval: null,
      current_lot: {
        number: "-",
        variety: "-",
        origin: "-",
      },
      items: [
        { pallet: 567000040, cajas: 200, formato: "4.4 Oz / 12x125Gr" },
        { pallet: 567000021, cajas: 200, formato: "4.4 Oz / 12x125Gr" },
        { pallet: 567000089, cajas: 200, formato: "4.4 Oz / 12x125Gr" },
        { pallet: 567000038, cajas: 200, formato: "4.4 Oz / 12x125Gr" },
      ],
    };
  },
  created() {
    this.subscribe();
    this.getProcess(this.$route.params.process.id);
    this.time = this.refreshTime();
  },
  mounted() {
    if (!this.$route.params.process) {
      this.$router.push({ path: "/" });
    }
    this.counterInterval = setInterval(() => {
      this.time = this.refreshTime();
      this.now = this.refreshNow();
    }, 1000);
  },
  destroyed() {
    this.disconnect();
    clearInterval(this.counterInterval);
  },
  methods: {
    subscribe() {
      pusher = new Pusher(this.pusher_id, { cluster: this.pusher_cluster });
      let channel = pusher.subscribe(this.$route.params.process.id.toString());
      channel.bind("traceability-in", (data) => {
        this.current_lot.number = data.lot_number;
        this.current_lot.variety = data.variety_name;
        this.current_lot.origin = data.origin_name;
        this.$store.commit("addRowsIn", data);
      });
      channel.bind("traceability-out", (data) => {
        this.$store.commit("addRowsOut", data);
      });
    },
    disconnect() {
      pusher.disconnect();
    },
    refreshNow() {
      const now = moment();
      return now.format("HH:mm:ss");
    },
    refreshTime() {
      const start = moment(this.$route.params.process.start_time);
      return start.startOf("second").fromNow();
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    async getProcess(evt) {
      const thisV = this;
      let url = "";
      thisV.loading = true;
      url = `api/v1/process/${evt}/detailed_production_plan_process/`;
      try {
        const response = await thisV.$axios({
          method: "GET",
          url: url,
        });
        thisV.$store.commit("updateProcess", response.data);
        thisV.loading = false;
      } catch (error) {
        thisV.loading = false;
        console.log(error);
        thisV.showAlert();
      }
    },
  },

  computed: {
    ...mapState(["pusher_id", "pusher_cluster"]),
    ...mapGetters(["totalExportable", "totalInNet", "totalExportableQuantity"]),
  },
};
</script>

<style></style>
